export default {
    ["receive"]: "Primi",
    ["mining_pool"]: "Rudarski bazen",
    ["account"]: "Račun",
    ["pool_data"]: "Podaci bazena",
    ["total_output"]: "Ukupna proizvodnja",
    ["valid_node"]: "Važeći čvor",
    ["participant"]: "Sudionik",
    ["user_revenue"]: "Prihod korisnika",
    ["activity"]: "Aktivnost",
    ["mining"]: "Rudarenje",
    ["liquidity"]: "Prihod od likvidnosti rudarenja",
    ["user_output"]: "Korisnička proizvodnja",
    ["address"]: "Adresa",
    ["quantity"]: "Količina",
    ["help_center"]: "Centar za pomoć",
    ["hep_center_tip"]: "Nadamo se da će vam pomoći",
    ["question1"]: "Kako se mogu pridružiti?",
    ["answer1"]: "Za sudjelovanje u likvidnosnom rudarenju bez gubitaka i jamstava, morate platiti ETH rudarsku naknadu kako biste dobili vaučer. ETH adresa novčanika mora biti zatražena samo jednom. Nakon uspjeha, rudarska dozvola će se automatski otvoriti.",
    ["question2"]: "Kako mogu podići novac?",
    ["answer2"]: "Možete svakodnevno pretvarati proizvedene tokene u USDT, a zatim pokrenuti povlačenje. USDT će automatski biti poslan na adresu novčanika koju ste dodali u čvor. Druge adrese nisu podržane.",
    ["question3"]: "Kako se računa prihod?",
    ["answer3"]: "Kada se uspješno pridružite, pametni ugovor počinje obrađivati vašu adresu putem čvora i započinje izračun prihoda.",
    ["audit_report"]: "Izvješće o reviziji",
    ["audit_report_tip"]: "Imamo sigurno revizijsko izvješće",
    ["partner"]: "Partner",
    ["partner_tip"]: "Naš poslovni partner",
    ["my_account"]: "Moj račun",
    ["mining_status"]: "Status rudarenja",
    ["wallet_balance"]: "Stanje novčanika",
    ["exchangeable"]: "Moguće zamijeniti",
    ["withdrawable"]: "Moguće povući",
    ["share_dividends"]: "Podijeljene dividende",
    ["my_level"]: "Moj nivo",
    ["level"]: "Nivo",
    ["hash_rate"]: "Hash stopa",
    ["output_interest"]: "Kamatna stopa izlaza",
    ["output_interest_rate"]: "Postotak kamatne stope izlaza",
    ["output_interest_cycle"]: "Ciklus kamatne stope izlaza",
    ["level_details"]: "Detalji nivoa",
    ["pair_price"]: "Cijena para",
    ["exchange"]: "Zamijeni",
    ["withdraw"]: "Povuci",
    ["redeem_all"]: "Iskoristi sve",
    ["total_balance"]: "Ukupno stanje",
    ["record"]: "Zapis",
    ["shared"]: "Podijeljeno",
    ["output"]: "Izlaz",
    ["back"]: "Natrag",
    ["no-data"]: "Nema podataka",
    ["received"]: "Primljeno",
    ["transfer"]: "Prijenos",
    ["share_friends"]: "Podijeli s prijateljima",
    ["my_share_link"]: "Moj link za dijeljenje",
    ["copy"]: "Kopiraj",
    ["share_tip"]: "Pošaljite svoj pozivni link, prijatelji se pridružuju čvoru putem vašeg linka, a vi ćete dobiti velikodušne nagrade u tokenima.",
    ["join_tip"]: "Pridružili ste se",
    ["copySuccess"]: "Link je kopiran",
    ["apply_mining_pool_rewards"]: "Prijavi se za nagrade rudarskog bazena",
    ["standard"]: "Standardno",
    ["required"]: "Obavezno",
    ["countdown"]: "Odbrojavanje",
    ["host_day"]: "Dan",
    ["reward"]: "Nagrada",
    ["i_know"]: "Razumijem",
    ["popup_miniing_title"]: "Prijavi se za nagrade rudarskog bazena",
    ["popup_miniing_conn1"]: "ERC-20 Pametni ugovor",
    ["popup_miniing_conn2"]: "ERC-20 pametni ugovor rudarskog bazena uskoro započinje sljedeći ETH događaj dijeljenja. Prijavite se sada. Nakon početka događaja, pružatelji likvidnosti mogu dijeliti više nagrada.",
    ["popup_miniing_btn"]: "Prijavi se za nagrade",
    ["recharge"]: "Nadoplata",
    ["recharge_currency"]: "Adresa za polog",
    ["recharge_scan code transfer"]: "Spremi QR",
    ["recharge_amount"]: "Iznos pologa",
    ["please enter"]: "Unesite",
    ["recharge_upload"]: "Kliknite za prijenos slika",
    "confirm": "Potvrdi",
    ["rachange_link_coinbase"]: "Službeni kanal za polog Coinbase-a",
    ["rachange_link_binance"]: "Službeni kanal za polog Binance-a",
    ["rachange_link_huobi"]: "Službeni kanal za polog Huobi-a",
    "cancel": "Otkaži",
    ["addressCopySuccess"]: "Adresa je kopirana",
    ["recharge_record"]: "Zapis o nadoplati",
    ["all"]: "Sve",
    ["processing"]: "U obradi",
    ["credited"]: "Uspješno",
    ["fail"]: "Neuspješno",
    ["amount"]: "Iznos",
    ["state"]: "Status",
    ["pulling_text"]: "Povucite dolje za osvježavanje...",
    ["loosing_text"]: "Otpustite za osvježavanje...",
    ["time"]: "Vrijeme",
    ["record_tip"]: "Napomena",
    "activity-top-title": "ETH Airdrop nagrade",
    "activity-top-big-title": "Dolazi 1 milijun ETH Airdrop nagrada",
    "activity-top-tip": "Dovršite jednostavne zadatke na stranici čvora rudarskog bazena vašeg DeFi projekta kako biste podijelili ogromnu nagradu iz airdropa.",
    "activity-end": "Aktivnost završena",
    "activity-join": "Razumijem",
  }
  